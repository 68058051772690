import React from 'react'

const ComingSoon = () => {
  return (
    <section id='start-session' className="pb-120 mt-minus-300">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contest-wrapper">
                <div className="contest-wrapper__header pt-120">
                  <h2 className="contest-wrapper__title">Coming Soon...</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default ComingSoon
