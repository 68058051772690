import axios from "axios";
import Swal from 'sweetalert2';
import React, { useContext } from "react"
import { Outlet, useNavigate } from "react-router-dom";
import { StateContext } from "../../Providers/stateProvider";
import NavBar from "../Nav";
import Footer from "../Footer";
import Notifications from "../Notification";
// import styles from './styles.module.css';

export const DashboardLayout = () => {
  const { _, dispatch } = useContext(StateContext)
  const navigate = useNavigate();

  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response?.status === 401) {
      dispatch({
        type: 'LOGOUT'
      })
      // Swal.fire({
      //   title: 'Login to continue./////',
      //   text: 'Please login to continue.',
      //   icon: 'info',
      //   didClose: () => {
      //     navigate('/login')
      //   }
      // })
    }
    return Promise.reject(error);
  });

  return <div className="page-wrapper">
    <NavBar />
    <Notifications />
    <main className="nxl-container">
      <div className="nxl-content pb-120">
        <Outlet />
      </div>
      <Footer />
    </main>
    {/* <div className="scroll-to-top">
      <span className="scroll-icon">
        <i className="las la-arrow-up"></i>
      </span>
    </div> */}
  </div>
}

export default DashboardLayout;
