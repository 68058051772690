import React, { useContext, useEffect, useState } from 'react'
import Breadcrumb from '../../components/Breadcrumb'
import { Link, useNavigate } from 'react-router-dom'
import { StateContext } from '../../Providers/stateProvider';
import types from '../../Providers/reducers/user/type';
import getUserFingerprint from '../../utils/userFingerprint';
import axios from 'axios';
import Swal from 'sweetalert2';

import './style.css';


const Login = () => {
  const { user: { loading }, dispatch } = useContext(StateContext);
  const navigate = useNavigate();

  const [form, setForm] = useState({
    phone_number: '',
    password: '',
  })
  const [disabled, setDisabled] = useState(false)
  // const [error, setError] = useState('');

  const handleChange = (target, value) => {
    setForm({
      ...form,
      [target]: value
    })
  }

  useEffect(() => {
    // if (form.password !== form.confirm_password) {
    //   setError('Password mismatch.')
    // } else {
    //   setError('')
    // }
    if (form.phone_number !== '' && form.password !== '') {
      setDisabled(false)
    } else { setDisabled(true) }
  }, [form])

  console.log(disabled, '========')
  const submitForm = async (e) => {
    e.preventDefault();
    try {
      dispatch({
        type: types.LOGIN
      })
      const fingerprint = await getUserFingerprint();
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
        ...form
      }, {
        headers: {
          fingerprint: fingerprint.fingerprintHash,
          fingerprintData: JSON.stringify(fingerprint.fingerprintData)
        }
      });

      if (response.status) {
        dispatch({
          type: types.LOGIN_SUCCESS,
          payload: {
            token: response.headers['access-token'],
            data: response.data
          }
        });
        Swal.fire({
          title: 'Login Successful.',
          text: '...',
          icon: "success",
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true,
          // didClose: () => {
          // }
        })
        navigate('/user-panel')
      } else {
        // dispatch({
        //   type: types.LOGIN_ERROR,
        //   payload: { error: error.message }
        // });
        Swal.fire({
          title: 'Login Error.',
          text: response.message,
          icon: "error",
          // didClose: () => {
          //   navigate('/user-panel')
          // }
        })
      }
    } catch (error) {
      dispatch({
        type: 'ERROR',
        payload: { error: error.response.data.message || error.message }
      });
    }
  }

  return (
    <div>
      <Breadcrumb page='login' />
      <div className="container"  id='auth'>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6" >
            <div className="mt-30 pb-120 mt-minus-300">
              <div className="account-form-area">
                <h3 className="title">Welcome Back</h3>
                <div className="account-form-wrapper">
                  <form onSubmit={(e) => submitForm(e, form)}>
                    <div className="form-group">
                      <label>Phone Number <sup>*</sup></label>
                      <input
                        type="text"
                        name="login_name"
                        id="login_name"
                        placeholder="09011119999"
                        onChange={(e) => handleChange('phone_number', e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label>password <sup>*</sup></label>
                      <input
                        type="password"
                        name="login_pass" id="login_pass"
                        placeholder="password"
                        onChange={(e) => handleChange('password', e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-wrap justify-content-between mt-2">
                      <Link to="/forgot-password" className="link">Forgot Password?</Link>
                    </div>
                    <div className="form-group text-center mt-5">
                      <button disabled={disabled || loading} className="cmn-btn">{ loading ? 'loading...' : 'login'}</button>
                    </div>
                  </form>
                  <p className="text-center mt-4">Don't have an account? <Link to="/register"> Sign Up Now</Link></p>
                  {/* <div className="divider">
                    <span>or</span>
                  </div>
                  <ul className="social-link-list">
                    <li><a href="#0"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="#0"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="#0"><i className="fab fa-google-plus-g"></i></a></li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
