import { useContext } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Routes } from "./navigation/routes";
import { StateContext } from "./Providers/stateProvider";
import PreLoader from "./components/PreLoader";


// const protectedRouter = createBrowserRouter([...ProtectedRoutes]);
const routes = createBrowserRouter([...Routes]);

function App() {
  const { loading } = useContext(StateContext)
  return <>
    {loading && <PreLoader />}
    <RouterProvider router={routes} />
  </>
}

export default App;
