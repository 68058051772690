/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import './styles.css'
import { useAuth } from '../AuthProvider'


const NavBar = () => {
  const { isAuthenticated } = useAuth()

  return (
    <header id='ll-nav' className="header">
      <div className="header__bottom">
        <div className="container">
          <nav className="navbar navbar-expand-xl p-0 align-items-center">
            <Link className="site-logo site-title" to="/">
              <img id='logo' src="/assets/images/ll_logo2.png" alt="site-logo" />
              <span className="logo-icon"><i className="flaticon-fire"></i>
              </span>
            </Link>
            <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="menu-toggle"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav main-menu ms-auto">
                <li><NavLink to="/"  >Home</NavLink></li>
                {/* <li className="">
                  <NavLink to="/contest"  >Contest</NavLink>
                </li> */}
                <li className="">
                  <a href="/quiz"  >Quiz</a>
                </li>

                <li className="">
                  <NavLink to="/games"  >Games</NavLink>
                </li>

                {/* <li><NavLink to="/referral"  >Referral</NavLink></li> */}
                
                <li><NavLink to="/activity"  >Activity</NavLink></li>
                {/* <li className="">
                  <NavLink to="/pages"  >pages</NavLink>
                </li>
                <li><NavLink to="/contact"  >contact</NavLink></li> */}
              </ul>
              <div className="">
                {isAuthenticated ? <NavLink to="/user-panel" className="user__btn"><i className="las la-user"></i></NavLink> :
<a href='/login' className="cmn-btn style--three btn--sm" >Login</a> }
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default NavBar
