import initialState from './state';
import types from './type';

const userReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.REGISTER:
      return {
        ...state,
        loading: true,
      }

    case types.REGISTER_SUCCESS:
      localStorage.setItem('token', action.payload.token)
      localStorage.setItem('isLoggedIn', true)
      localStorage.setItem('user_data', JSON.stringify(action.payload.data))
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        data: {
          ...state.data,
          ...action.payload.data
        }
      }

    case types.REGISTER_ERROR:
      return {
        ...state,
        loading: false
      }

    case types.LOGIN:
      return {
        ...state,
        loading: true,
      }

    case types.LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token)
      localStorage.setItem('isLoggedIn', true)
      localStorage.setItem('user_data', JSON.stringify(action.payload.data))

      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        data: {
          ...state.data,
          ...action.payload.data
        }
      }

    case types.LOGIN_ERROR:
      return {
        ...state,
        loading: false
      }

    case types.GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: [
          ...action.payload.data
        ]
      }

    case types.GET_QUIZ_SESSION_SUCCESS:
      return {
        ...state,
        quizSession: {
          ...action.payload
        }
      }

    case types.GET_REFERRALS_SUCCESS:
      return {
        ...state,
        referrals: {
          ...action.payload
        }
      }

    case types.LOGOUT:
      // console.log('logged out????/')
      // localStorage.setItem('isLoggedIn', true)
      return {
        ...state,
        data: {},
        isLoggedIn: false,
        token: ''
      }

    case types.ERROR:
      return {
        ...state,
        loading: false,
        showNotification: true,
        message: action.payload.error,
        type: 'error'
      }
    default:
      return state;
  }

}

export default userReducer;
