import React, { useContext, useEffect, } from 'react'
import Breadcrumb from '../../components/Breadcrumb/'
import Questions from '../../components/quiz/Questions'
import StartSession from '../../components/quiz/StartSession'
import { StateContext } from '../../Providers/stateProvider'
import axios from 'axios'
import getUserFingerprint from '../../utils/userFingerprint'
import quizTypes from '../../Providers/reducers/quiz/type';

const Quiz = () => {
  const { quiz: { startQuiz, loading, question, endTime, categories }, dispatch } = useContext(StateContext)

  const fetchCategories = async () => {
    try {
      const fingerprint = await getUserFingerprint();
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/quiz/categories`, {
        headers: {
          fingerprint: fingerprint.fingerprintHash,
          location: JSON.stringify(fingerprint.location),
          fingerprintData: JSON.stringify(fingerprint.fingerprintData)
        }
      })
      dispatch({
        type: quizTypes.FETCH_CATEGORIES,
        payload: response.data
      })
    } catch (error) {
      dispatch({
        type: 'TOGGLE_NOTIFICATION',
        payload: {
          showNotification: true,
          message: error.message,
          type: 'error'
        }
      })
    }
  }

  const submitBeginSession = async (e, data) => {
    try {

      e.preventDefault();
      dispatch({
        type: quizTypes.START_QUIZ_SESSION
      })
      const fingerprint = await getUserFingerprint();
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/quiz/start`, {
        ...data
      }, {
        headers: {
          fingerprint: fingerprint.fingerprintHash,
          location: JSON.stringify(fingerprint.location),
          fingerprintData: JSON.stringify(fingerprint.fingerprintData)
        }
      })

      if (response.data.status) {
        dispatch({
          type: quizTypes.START_QUIZ_SESSION_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: quizTypes.START_QUIZ_SESSION_FAIL,
          payload: { error: '' }
        })
        dispatch({
          type: 'TOGGLE_NOTIFICATION',
          payload: { message: response.data.message, showNotification: true }
        });
      }
    } catch (error) {
      console.log(error.response)
      dispatch({
        type: 'TOGGLE_NOTIFICATION',
        payload: { message: error?.response?.data?.message || 'Some error occurred pla try again later.', showNotification: true, type: 'error' }
      });
      dispatch({
        type: quizTypes.START_QUIZ_SESSION_FAIL,
        payload: { error: '' }
      })
    }

  }

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // function triggerAt(dateTime, callback = () => { }) {
  //   const now = new Date().getTime();
  //   const triggerTime = new Date(dateTime).getTime();
  //   const delay = triggerTime - now;

  //   if (delay > 0) {
  //     setTimeout(callback, delay);
  //   } else {
  //     console.warn("The specified time is in the past.");
  //   }
  // }

  // useEffect(() => {
  //   triggerAt(endTime, () => {
  //     console.log("Time's up!");
  //     // move to results page or show error page...
  // })
  // }, [endTime])

  return (
    <>
      <Breadcrumb page={'Quiz'} />
      {startQuiz ?
        <Questions
          question={question}
          endTime={endTime}
        />
        :
        <StartSession
          loading={loading}
          categories={categories}
          submitBeginSession={submitBeginSession}
        />}
    </>
  )
}

export default Quiz
