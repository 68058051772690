import React from 'react'
import QuizSummary from '../card/quizSummary'

const QuizOver = ({ quizData }) => {
  console.log(quizData)
  return (
    <div className="">
      <div className="">
        <QuizSummary quizData={quizData} />
      </div>
    </div>
  )
}

export default QuizOver
