import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { StateContext } from '../../Providers/stateProvider';

const Menu = () => {
  const {_, dispatch} = useContext(StateContext);
  const logout = () => {
    console.log('logging out...')
    dispatch({
      type: 'LOGOUT'
    })
  }
  return (
    <div className="user-action-card">
      <ul className="user-action-list">
        <li>
          <NavLink to="/user-panel" end>Home
            {/* <span className="badge">04</span> */}
          </NavLink>
        </li>
        {/* <li><NavLink to="user-panel/info">Personal Information</NavLink></li> */}
        {/* <li><NavLink to="user-transaction.html">Transactions</NavLink></li> */}
        <li><NavLink to="referral" end>Referral Program</NavLink></li>
        <li><NavLink to="shop" end>Shop</NavLink></li>
        <li><NavLink to="/contact">Help Center</NavLink></li>
        <li><a  style={{ cursor:'pointer'}} onClick={logout}>Log Out</a></li>
      </ul>
    </div>
  )
}

export default Menu
