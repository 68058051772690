import React from 'react'

const Jackpot = () => {
  const prices = [
    {
      title: 'Recharge card pins',
      icon: 'calls',
      available: true,
      code: 'RCP-92',
      link: '/quiz'
    },
    {
      title: 'Various data pans',
      icon: 'data',
      available: false,
      code: 'VDP22'
    },
    {
      title: 'Prepaid electric pin',
      icon: 'electric',
      available: false,
      code: 'PEP-JT0'
    },
    {
      title: 'TV subscription',
      icon: 'tv',
      available: false,
      code: 'TSP009'
    }
  ]
  return (
    <section className="jackpot-section section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-header text-center">
              <h2 className="section-title">Jackpots</h2>
              <p>Can win from Quiz, Daily check-ins, Fastest fingers or  Data O'clock and try for a chance to win as prize</p>
            </div>
          </div>
        </div>
        <div className="row gy-5">
          {prices.map(p => (
            <div key={p.title} className="col-xl-4 col-md-6 mb-30">
              <div className="contest-card">
                <div className="contest-card__thumb">
                  <img src={`/assets/images/elements/${p.icon}.png`} style={{ width: '40%' }} alt="price_" />
                  <a href="#0" className="action-icon"><i className="far fa-heart"></i></a>
                  <div className="contest-num">
                    <span className="number">{p.code}</span>
                  </div>
                </div>
                <div className="contest-card__content">
                  <div className="left">
                    <h5 className="contest-card__name">{p.title}</h5>
                  </div>
                  <div className="right">
                    {p.available ? <span className="contest-card__price">
                      <a href="/quiz">
                        Play now
                      </a>
                    </span> : <span style={{ fontSize: '26px' }} className="contest-card__price">Coming soon!</span>}
                    {/* <p>ticket price</p> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Jackpot
