const app = {
  fastestFingers: {
    pin: '',
    loading: false,
    endTime: new Date(),
    startTime: new Date(),
    error: '',
    timerStarted: false,
    coolOff: 5,
    showNotification: false,
    notificationType: 'success',
    message: ''
  }
}

export default app;
