import Home from "../pages/Home";
import Quiz from "../pages/Quiz";
// import Page404 from '../pages/404/Page404'
// import ResetPassword from "../pages/auth/ResetPassword";
import AppLayout from "../components/appLayout/AppLayout";
// import ContestPage from "../pages/Contest";
import Games from "../pages/Games";
// import Referral from "../pages/Referral";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import Activity from "../pages/Activity";
import ClickTheButton from "../pages/Activity/ClickTheButton";
import NotFound from "../pages/404";
import UserPanel from "../pages/UserPanel";
import ProtectedRoutes from "../components/ProtectedComponents";
import Referral from "../pages/UserPanel/Referral";

export const Routes = [
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <>some error occurred, pls contact admin.</>,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/quiz",
        element: <Quiz />
      },
      {
        path: "/games",
        element: <Games />
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/register',
        element: <Register />
      },
      {
        path: '/activity',
        element: <Activity />,
      },
      {
        path: '/click-the-button',
        element: <ClickTheButton />
      },
      {
        path: '/user-panel',
        element: <ProtectedRoutes />,
        children: [
          {
            path: '',
            element: <UserPanel />,
          },
          {
            path: 'referral',
            element: <Referral />,
          },
          {
            path: 'settings',
            element: <Activity />,
          },
        ],
      },
      // {
      //   path: '/user-panel',
      //   element: <ProtectedRoutes element={<UserPanel />} />,
      // },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];
