import React, { useContext } from 'react'
import Clock from '../Clock/e2';
import { StateContext } from "../../Providers/stateProvider";

const Contest = ({ handleCountdownEnd }) => {
  const alt_pin = [
    '* ** *** ** *',
  ]
  const { app: { fastestFingers: {
    pin,
    endTime
  }}} = useContext(StateContext)
  console.log('--------', pin)
  return (
    <section className="position-relative pt-120 pb-120">
      <div className="bg-el"><img src="/assets/images/elements/contest-bg.png" alt="bg" /></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
            <div className="section-header text-center">
              <span className="section-sub-title">Fastest fingers</span>
              <h2 className="section-title">
                {pin ? pin : alt_pin[0]}
              </h2>
              <p>
                <Clock
                  onCountdownEnd={handleCountdownEnd}
                  targetDate={endTime}
                  pin={pin}
                  showMilliseconds
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contest;
