import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Modal from '../Modal';
// import ComingSoon from '../ComingSoon';

const Hero = () => {
  const [show, setShow] = useState(false)

  const openAuthModal = () => {
    setShow(true)
  }
  return (
    <section className="hero">
      <Modal title='Login' show={show} onClose={() => setShow(false)}>
        Coming soon...
      </Modal>
    <div className="hero__shape"><img src="/assets/images/elements/hero-shape.jpg.png" alt="shape" /></div>
    <div className="hero__element"><img src="/assets/images/elements/hero-building.png" alt="building" /></div>
    <div className="hero__car wow bounceIn" data-wow-duration="0.5s" data-wow-delay="1s">
      <img src="/assets/images/elements/car-shadow.png" alt="car-shadow" className="car-shadow" />
      <img src="/assets/images/elements/car-ray.png" alt="ray" className="car-ray" />
      <img src="/assets/images/elements/car-light.png" alt="light" className="car-light" />
      <img src="/assets/images/ll_logo2.png" alt="car" className="hero-car" />
      <img src="/assets/images/elements/car-star.png" alt="start" className="car-star" />
    </div>
    <div className="container">
      <div className="row justify-content-center justify-content-lg-start">
        <div className="col-lg-6 col-md-8">
          <div className="hero__content">
            <div className="hero__subtitle wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">Take a chance on your mind get</div>
            <h2 className="hero__title wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">recharged!</h2>
            <p className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">Simply take a quiz or perform activities and you might just get rewarded! Will you be our next lucky winner?</p>
            <div className="hero__btn wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.9s">
              <a href="/quiz" style={{ marginRight: '5px' }} className="cmn-btn">Start Quiz Now</a>
              <Link onClick={openAuthModal} className="btn-border">Join Us</Link>
              {/* <Link className="video-btn" to="https://www.youtube.com/embed/d6xn5uflUjg" data-rel="lightcase:myCollection"><i className="fas fa-play"></i></Link> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="hero__thumb">
            <img src="/assets/images/l_logo2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Hero
