import React from 'react'
import { Helmet } from 'react-helmet-async';

const OnClick = () => {
  return (
    <Helmet>
       <script id="me-first" data-cfasync="false" type="text/javascript" src="/assets/js/onClick1.js"></script>
       <script id="me-me" data-cfasync="false" type="text/javascript" src="/assets/js/onClick2.js"></script>
    </Helmet>
  )
}

export default OnClick;
