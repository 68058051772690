const types = {
  START_QUIZ_SESSION: 'START_QUIZ_SESSION',
  START_QUIZ_SESSION_SUCCESS: 'START_QUIZ_SESSION_SUCCESS',
  START_QUIZ_SESSION_FAIL: 'START_QUIZ_SESSION_FAIL',

  SUBMIT_QUESTION: 'SUBMIT_QUESTION',
  SUBMIT_QUESTION_SUCCESS: 'SUBMIT_QUESTION_SUCCESS',
  SUBMIT_QUESTION_FAIL: 'SUBMIT_QUESTION_FAIL',

  QUIZ_OVER: 'QUIZ_OVER',

  FETCH_CATEGORIES: 'FETCH_CATEGORIES',

  CLICK_BUTTON: 'CLICK_BUTTON',
  CLICK_BUTTON_SUCCESS: 'CLICK_BUTTON_SUCCESS',
  CLICK_BUTTON_FAIL: 'CLICK_BUTTON_FAIL',
}

export default types;
