const types = {
  FETCH_FASTEST_FINGER_PIN: 'FETCH_FASTEST_FINGER_PIN',
  FETCH_FASTEST_FINGER_PIN_SUCCESS: 'FETCH_FASTEST_FINGER_PIN_SUCCESS',
  FETCH_FASTEST_FINGER_PIN_FAIL: 'FETCH_FASTEST_FINGER_PIN_FAIL',

  ERROR: 'ERROR',
  TOGGLE_NOTIFICATION: "TOGGLE_NOTIFICATION",
}

export default types;
