import React, { useContext, useEffect, useState } from 'react'
import Breadcrumb from '../../components/Breadcrumb'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { StateContext } from '../../Providers/stateProvider';
import types from '../../Providers/reducers/user/type';
import getUserFingerprint from '../../utils/userFingerprint';
import Swal from 'sweetalert2';

import './style.css';
const Register = () => {
  const { user: { loading }, dispatch } = useContext(StateContext);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, _] = useSearchParams();

  const [form, setForm] = useState({
    full_name: '',
    network: '',
    phone_number: '',
    password: '',
    confirm_password: '',
    referral_code: searchParams.get('ref') || '',
    't&c': false
  })
  const [disabled, setDisabled] = useState(false)
  const [error, setError] = useState('');
  const network = ['mtn', 'airtel', 'glo', '9mobile']

  console.log(searchParams.get('ref'))
  const handleChange = (target, value) => {
    setForm({
      ...form,
      [target]: value
    })
  }

  useEffect(() => {
    if (form.password !== form.confirm_password) {
      setError('Password mismatch.')
    } else {
      setError('')
    }
    if (
      form.full_name !== '' &&
      form.network !== '' &&
      form.phone_number !== '' &&
      form.password !== '' &&
      form.confirm_password !== '' &&
      form.referral_code !== '' &&
      form['t&c'] !== false
    ) {
      if (form.password === form.confirm_password) {
        setDisabled(false)
      }
    } else { setDisabled(true) }
  }, [form])
  const submitForm = async (e) => {
    e.preventDefault();
    try {
      dispatch({
        type: types.REGISTER
      })
      const fingerprint = await getUserFingerprint();
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, {
        ...form
      }, {
        headers: {
          fingerprint: fingerprint.fingerprintHash,
          fingerprintData: JSON.stringify(fingerprint.fingerprintData)
        }
      });

      if (response.status) {
        dispatch({
          type: types.REGISTER_SUCCESS,
          payload: {
            token: response.headers['access-token'],
            data: response.data
          }
        });
        Swal.fire({
          title: 'Registration Successful.',
          text: 'You have successfully registered, logging you in now',
          icon: "info",
          didClose: () => {
            navigate('/user-panel')
          }
        })
      } else {
        dispatch({
          type: types.REGISTER_ERROR,
          payload: { error: error.message }
        });
        Swal.fire({
          title: 'Registration Error.',
          text: response.message	,
          icon: "error",
          // didClose: () => {
          //   navigate('/user-panel')
          // }
        })
      }
    } catch (error) {
      dispatch({
        type: 'ERROR',
        payload: { error: error.response.data.message || error.message }
      });
    }
  }

  return (
    <div>
      <Breadcrumb page='login' />
      <div className="container" id='auth'>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6" >
            <div className="mt-30 pb-120 mt-minus-300">
              <div className="account-form-area">
                <h3 className="title">Create a free account</h3>
                <div className="account-form-wrapper">
                  <form onSubmit={(e) => submitForm(e, form)}>
                    <div className="form-group">
                      <label>Full Name <sup>*</sup></label>
                      <input
                        type="text"
                        onChange={(e) => handleChange('full_name', e.target.value)}
                        name="login_pass" id="login_pass" placeholder="John Doe"
                      />
                    </div>

                    <div className="form-group">
                      <label>Network <sup>*</sup></label>
                      <select onChange={(e) => handleChange('network', e.target.value)}>
                        <option>- Select your Network -</option>
                        {network.map(n => (
                          <option key={n} value={n}>{n.toUpperCase()}</option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Phone number <sup>*</sup></label>
                      <input
                        type="text"
                        name="phone_number" id="phone_number" placeholder="09011119999"
                        onChange={(e) => handleChange('phone_number', e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label>password <sup>*</sup></label>
                      <input
                        type="password"
                        name="login_pass" id="login_pass" placeholder="password"
                        onChange={(e) => handleChange('password', e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label>confirm password <sup>*</sup></label>
                      <input
                        type="password" name="confirm_pass" id="confirm_pass"
                        placeholder="confirm password"
                        onChange={(e) => handleChange('confirm_password', e.target.value)}
                      />
                      <span style={{ color: 'red' }}>{error}</span>
                    </div>

                    <div className="form-group">
                      <label>Referral code</label>
                      <input
                        type="text" name="referral_code" id="referral_code"
                        placeholder="referral code"
                        value={form.referral_code}
                        onChange={(e) => handleChange('referral_code', e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-wrap mt-2">
                      <div className="custom-checkbox">
                        <input type="checkbox" onChange={(e) => handleChange('t&c', e.target.checked)} name="id-2" id="id-2" />
                        <label htmlFor="id-2">I agree to the</label>
                        <span className="checkbox"></span>
                      </div>
                      <a href="#0" className="link ml-1">Terms, Privacy Policy</a>
                    </div>
                    <div className="form-group text-center mt-5">
                      <button disabled={disabled || loading} className="cmn-btn">Signup</button>
                    </div>
                  </form>
                  <p className="text-center mt-4">Don't have an account? <Link to="/login"> Login</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
