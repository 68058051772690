export const fontList = [
  "Arial",
  "Arial Black",
  "Arial Narrow",
  "Arial Rounded MT Bold",
  "Bookman Old Style",
  "Bradley Hand ITC",
  "Brush Script MT",
  "Comic Sans MS",
  "Courier",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Bright",
  "Lucida Calligraphy",
  "Lucida Console",
  "Lucida Fax",
  "Lucida Handwriting",
  "Lucida Sans",
  "Lucida Sans Typewriter",
  "Lucida Sans Unicode",
  "Palatino Linotype",
  "Segoe Print",
  "Segoe Script",
  "Segoe UI",
  "Segoe UI Light",
  "Segoe UI Semibold",
  "Segoe UI Symbol",
  "Tahoma",
  "Times",
  "Times New Roman",
  "Trebuchet MS",
  "Verdana",
  "MS Gothic",
  "MS PGothic",
  "MS UI Gothic",
  "MS Mincho",
  "MS PMincho",
  "MS UI Mincho",
  "Meiryo",
  "Meiryo UI",
  "Yu Gothic",
  "Yu Gothic UI",
  "Yu Mincho",
  "Yu Mincho Demibold",
  "Yu Mincho Light",
  "Yu Mincho Medium",
  "Apple Chancery",
  "Apple Color Emoji",
  "Apple LiGothic",
  "Apple SD Gothic Neo",
  "Apple Symbols",
  "Arial Hebrew",
  "Arial Hebrew Scholar",
  "Arial Rounded MT Bold",
  "Arial Unicode MS",
  "Baskerville",
  "Big Caslon",
  "Brush Script MT",
  "Charter",
  "Chalkboard",
  "Chalkboard SE",
  "Chalkduster",
  "Comic Sans MS",
  "Courier",
  "Courier New",
  "Didot",
  "Futura",
  "Geneva",
  "Gill Sans",
  "Helvetica",
  "Helvetica Neue",
  "Herculanum",
  "Hoefler Text",
  "Impact",
  "Lucida Grande",
  "Lucida Sans Typewriter",
  "Marker Felt",
  "Menlo",
  "Monaco",
  "New York",
  "Noteworthy",
  "Optima",
  "Palatino",
  "Papyrus",
  "Phosphate",
  "Rockwell",
  "Savoye LET",
  "SignPainter",
  "Snell Roundhand",
  "Tahoma",
  "Times",
  "Times New Roman",
  "Trebuchet MS",
  "Verdana",
  "Zapf Dingbats",
  "Zapfino"
];
