import React from 'react'
import './styles.css';
import { Link } from 'react-router-dom';

const QuizSummary = ({
  quizData: {
    recharge_pin,
    passed,
    failed,
    score,
    cool_down_period
  }
}) => {
  return (
    <section >

      <div className="winner-details-wrapper bg_img" style={{ paddingTop: '2px' }}>
        {/* <div className="left"><img src="/assets/images/contest/1.png" alt="image" /></div> */}
        <div className="body" style={{ width: '100%' }}>

          <h4 className="title">Congratulations!</h4>

          <p className="contest-date">
            <p>
              <span>{recharge_pin?.network?.toUpperCase()}</span>
            </p>
            <p>
              {recharge_pin?.pin_code || recharge_pin?.message}
            </p>
          </p>
          <div className="line"></div>
          <div style={{ textAlign: 'right' }}>

            <h4 className="title">Stats:</h4>
            <p>Questions answered: <span className="num">{Number(failed) + Number(passed)}</span></p>
            <p>Correct: <span className="num">{passed}</span></p>
            <p>Failed: <span className="num">{failed}</span></p>
            <hr />
            <p>Score: <span className="num">{score}</span></p>
          </div>
          <div className="line"></div>
          <p>Please try again {cool_down_period}.</p>
          <div className="btn-grp">
            {/* <a href="#0" className="btn-border">Alerts</a> */}
            <Link to="/click-the-button" className="btn-border">Reduce wait time</Link>
          </div>
        </div>
        {/* <div className="right"><img src="/assets/images/contest/7.png" alt="image" /></div> */}
      </div>

    </section>
  )
}

export default QuizSummary
