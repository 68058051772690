import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className="error-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="error-wrapper">
              <div className="error-wrapper__inner">
                <img src="/assets/images/elements/error.png" alt="404" />
              </div>
              <div className="error-wrapper__content">
                <h2 className="title">The page you are looking for doesn't exist!</h2>
                <Link to="/" className="cmn-btn d-inline-flex flex-wrap align-items-center active">GO BACK HOME <i className="las la-long-arrow-alt-right"></i></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
