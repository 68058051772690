import React, { useEffect, useRef } from 'react'

const Modal = ({ title, size='sm', show, onClose, children }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show, onClose]);

  return (
    <div
      className={`modal fade ${show ? 'show' : ''}`}
      id="loginModal"
      tabIndex="1"
      role="dialog"
      style={{ display: show ? "block" : "none" }}
      aria-modal="true"
    >
      <div 
      // style={{ width: '80%', maxWidth: modalSize[size]}}  
      ref={modalRef} className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-body">
            <div className="account-form-area">
              <button type="button" className="close-btn" data-bs-dismiss="modal" onClick={onClose} aria-label="Close"><i className="las la-times"></i></button>
              <h3 className="title">{title}</h3>
              <div className="account-form-wrapper">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
