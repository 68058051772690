import React from 'react'
import Breadcrumb from '../../components/Breadcrumb'
import ComingSoon from '../../components/ComingSoon'

const Games = () => {
  return (
    <>
    <Breadcrumb page={'Games'} />
     <ComingSoon />
    </>
  )
}

export default Games
