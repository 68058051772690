import appReducer from './reducers/app';
import userReducer from './reducers/user';
import quizReducer from './reducers/quiz';

const mainReducer = (state, action) => ({
    app: appReducer(state.app, action),
    user: userReducer(state.user, action),
    quiz: quizReducer(state.quiz, action),
});

export default mainReducer;
