import { useContext, useEffect, useState } from 'react'
import UpComingActivities from '../../components/UserPanel/UpComingActivities'
import { StateContext } from '../../Providers/stateProvider'
import getUserFingerprint from '../../utils/userFingerprint'
import axios from 'axios'
import moment from 'moment'
import types from '../../Providers/reducers/user/type'

const UserPanel = () => {
  const { user: { data, activities, quizSession, referrals, token }, dispatch } = useContext(StateContext)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const fetchQuizSession = async () => {
    try {
      const fingerprint = await getUserFingerprint();
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/quiz/sessions-history?page=${page}&limit=${limit}`, {
        headers: {
          authorization: `Bearer ${token}`,
          fingerprint: fingerprint.fingerprintHash,
          location: JSON.stringify(fingerprint.location),
          fingerprintData: JSON.stringify(fingerprint.fingerprintData)
        }
      })

      if (response.status) {
        dispatch({
          type: types.GET_QUIZ_SESSION_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: types.GET_QUIZ_SESSION_ERROR,
          payload: { error: response.message }
        });
      }
    } catch (error) {
      if (error.response.status !== 401) {
        dispatch({
          type: 'ERROR',
          payload: { error: error.response.data.message || error.message }
        });
      }
    }
  }

  const loadMore = async () => {
    
  }

  useEffect(() => {
    fetchQuizSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div className="col-lg-8 mt-lg-0 mt-4">
        <UpComingActivities activities={activities} dispatch={dispatch} />

        <div className="past-draw-wrapper">
          <h3 className="title">Quiz Session</h3>
          <div className="table-responsive-lg">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Category</th>
                  <th>Recharge Pin</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {quizSession.data.length > 0 ? quizSession.data.map((data, index) => (

                  <tr>
                    <td><span className="date">{moment(data.date).format('DD.MMM.yyyy')}</span></td>
                    <td><span className="contest-no">{data.category}</span></td>
                    <td>
                      <span>
                        {data.recharge_pin || '-'}
                      </span>
                    </td>
                    <td><span className="fail">{data.status}</span></td>
                  </tr>
                )) :
                  <tr>
                    <td colSpan='4' style={{ textAlign: 'center', color: 'white' }}>...No data yet... </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <div className="load-more">
            {quizSession.meta.page < quizSession.meta.pages &&
              <button onClick={loadMore} type="button">Show More <i className="las la-angle-down ml-2"></i></button>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default UserPanel
