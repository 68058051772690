// fingerprint.js
import axios from 'axios';
import { isMobile, browserName, browserVersion, deviceType, osName, osVersion } from 'react-device-detect';
import CryptoJS from 'crypto-js';
import { fontList } from './fontList';

const getUserFonts = () => {
  const availableFonts = fontList.filter(font => document.fonts.check(`12px "${font}"`));
  return { value: availableFonts,  };
};

const getUserDomBlockers = () => {
  // const start = performance.now();

  const testAdElement = document.createElement('div');
  testAdElement.className = 'adsbox';
  testAdElement.style.position = 'absolute';
  testAdElement.style.left = '-9999px';
  document.body.appendChild(testAdElement);

  // const duration = performance.now() - start;
  const isBlocked = testAdElement.offsetParent === null || testAdElement.offsetHeight === 0 || testAdElement.offsetWidth === 0;

  document.body.removeChild(testAdElement);

  return { isBlocked,  };
};

const measureTextWidth = (fontFamily) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = `16px ${fontFamily}`;
  return context.measureText('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ').width;
};

const getUserFontPreferences = () => {
  // const start = performance.now();
  const fontPreferences = {
    default: measureTextWidth('sans-serif'),
    apple: measureTextWidth('apple-system'),
    serif: measureTextWidth('serif'),
    sans: measureTextWidth('sans-serif'),
    mono: measureTextWidth('monospace'),
    min: measureTextWidth('Arial'), // Assuming Arial is available
    system: measureTextWidth('system-ui'),
  };
  // const duration = performance.now() - start;
  return { value: fontPreferences,  };
};

// const getUserAudioFingerprint = () => {
//   // const start = performance.now();
  
//   let audioContext = null;
//   let analyser = null;
//   let oscillator = null;
//   let scriptProcessor = null;

//   try {
//     const AudioContext = window.AudioContext || window.webkitAudioContext;
//     audioContext = new AudioContext();
//     analyser = audioContext.createAnalyser();
//     oscillator = audioContext.createOscillator();
//     scriptProcessor = audioContext.createScriptProcessor(4096, 1, 1);

//     oscillator.type = 'triangle';
//     oscillator.frequency.setValueAtTime(10000, audioContext.currentTime);
//     oscillator.connect(analyser);
//     analyser.connect(scriptProcessor);
//     scriptProcessor.connect(audioContext.destination);

//     let audioFingerprint = '';

//     scriptProcessor.onaudioprocess = (event) => {
//       const data = event.inputBuffer.getChannelData(0);
//       const hash = data.reduce((hash, value) => {
//         return hash + Math.abs(value);
//       }, 0);
//       audioFingerprint = hash.toString();
//     };

//     oscillator.start(0);
//     scriptProcessor.disconnect();
//     oscillator.disconnect();
//     analyser.disconnect();
//     audioContext.close();

//     // const duration = performance.now() - start;
//     return { value: parseFloat(audioFingerprint),  };
//   } catch (error) {
//     if (audioContext) {
//       audioContext.close();
//     }
//     console.error('Error generating audio fingerprint:', error);
//     return { value: null, };
//   }
// };


const getUserScreenFrame = () => {
  return { value: [30, 0, 40, 0],  };
};

const getUserLocation = async () => {
  try {
    const response = await axios.get('https://ipapi.co/json/');
    return response.data;
  } catch (error) {
    console.error('Error fetching location data:', error);
    return null;
  }
};

const getUserOsCpu = () => {
  return { value: navigator.oscpu || "Unknown",  };
};

const getUserLanguages = () => {
  return { value: [navigator.language || "Unknown", navigator.languages || ["Unknown"]],  };
};

const getUserColorDepth = () => {
  return { value: window.screen.colorDepth || 0,  };
};

const getUserDeviceMemory = () => {
  return { value: navigator.deviceMemory || "Unknown",  };
};

const getUserScreenResolution = () => {
  return { value: [window.screen.width, window.screen.height],  };
};

const getUserHardwareConcurrency = () => {
  return { value: navigator.hardwareConcurrency || 0,  };
};

const getUserTimezone = () => {
  return { value: Intl.DateTimeFormat().resolvedOptions().timeZone || "Unknown",  };
};

const getUserStorageInfo = () => {
  return {
    sessionStorage: { value: !!window.sessionStorage,  },
    localStorage: { value: !!window.localStorage,  },
    indexedDB: { value: !!window.indexedDB,  },
    openDatabase: { value: !!window.openDatabase,  },
  };
};

const getUserCpuClass = () => {
  return { value: navigator.cpuClass || "Unknown",  };
};

const getUserPlatform = () => {
  return { value: navigator.platform || "Unknown",  };
};

const getUserPlugins = () => {
  const plugins = Array.from(navigator.plugins).map(plugin => ({
    name: plugin.name,
    description: plugin.description,
    mimeTypes: Array.from(plugin).map(mimeType => ({
      type: mimeType.type,
      suffixes: mimeType.suffixes
    }))
  }));
  return { value: plugins,  };
};

const hashFingerprint = async (data) => {
  // const encoder = new TextEncoder();
  // const dataBuffer = encoder.encode(JSON.stringify(data));
  // const hashBuffer = await crypto.subtle.digest('SHA-256', dataBuffer);
  // const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex =  CryptoJS.SHA256(JSON.stringify(data)).toString(CryptoJS.enc.Hex);
  return hashHex;
};

const getUserFingerprint = async () => {
  const location = await getUserLocation();
  const fingerprintData = {
    deviceType: isMobile ? 'Mobile' : 'Desktop',
    deviceType2: deviceType,
    browserName,
    browserVersion,
    osName,
    osVersion,
    fonts: getUserFonts(),
    domBlockers: getUserDomBlockers(),
    fontPreferences: getUserFontPreferences(),
    // audio: getUserAudioFingerprint(),
    screenFrame: getUserScreenFrame(),
    osCpu: getUserOsCpu(),
    languages: getUserLanguages(),
    colorDepth: getUserColorDepth(),
    deviceMemory: getUserDeviceMemory(),
    screenResolution: getUserScreenResolution(),
    hardwareConcurrency: getUserHardwareConcurrency(),
    timezone: getUserTimezone(),
    ...getUserStorageInfo(),
    cpuClass: getUserCpuClass(),
    platform: getUserPlatform(),
    plugins: getUserPlugins(),
  };
  const fingerprintHash = await hashFingerprint(fingerprintData);
    return { fingerprintData, location, fingerprintHash };
};


export default getUserFingerprint;
