import Swal from 'sweetalert2'
import './styles.css'
import { useContext, useEffect } from 'react'
import { StateContext } from '../../Providers/stateProvider'

const Notifications = () => {
  const { app: { showNotification, message, type }, dispatch } = useContext(StateContext)
  const Toast = Swal.mixin({
    // toast: true,
    // position: 'top-end',
    iconColor: 'white',
    customClass: {
      popup: 'colored-toast',
    },
    // showConfirmButton: false,
    // timer: 2500,
    // timerProgressBar: true,
  })

  useEffect(() => {
    if (showNotification) {
      Toast.fire({
        icon: type,
        title: type,
        text: message,
        didClose: () => {
          dispatch({ type: 'TOGGLE_NOTIFICATION', payload: { showNotification: !showNotification, message: '' }})
        }
      })
    }
  }, [Toast, dispatch, message, showNotification, type])

  return (
    <div></div>
  )
}

export default Notifications
