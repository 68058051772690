const user = {
  data: JSON.parse( localStorage.getItem('user_data') || '{}') || {},
  isLoggedIn: localStorage.getItem('isLoggedIn') || false,
  token:  localStorage.getItem('token') || '',
  loading: false,
  activities: [],
  quizSession: {
    data: [],
    meta: {}
  },
  referrals: {
    data: [],
    meta: {}
  },
}

export default user;