/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react'
import Breadcrumb from '../../components/Breadcrumb';
import getUserFingerprint from '../../utils/userFingerprint';
import axios from 'axios';
import { StateContext } from '../../Providers/stateProvider';
import quizTypes from '../../Providers/reducers/quiz/type';

const ClickTheButton = () => {
  const { quiz: { clicks, cool_down_period }, dispatch } = useContext(StateContext);
  const [loading, setLoading] = useState(false);

  const buttonClick = async () => {
    try {
      window.open('https://daururizuroo.com/4/7600381', '_blank', 'noopener,noreferrer');
      setLoading(true)
      const fingerprint = await getUserFingerprint();
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/activity/button-click`, {}, {
        headers: {
          fingerprint: fingerprint.fingerprintHash,
          location: JSON.stringify(fingerprint.location),
          fingerprintData: JSON.stringify(fingerprint.fingerprintData)
        }
      })
      dispatch({
        type: quizTypes.CLICK_BUTTON_SUCCESS,
        payload: response.data
      })
      setLoading(false)
    } catch (error) {
      dispatch({ 
        type: quizTypes.CLICK_BUTTON_FAIL
      })

      dispatch({
        type: 'TOGGLE_NOTIFICATION',
        payload: {
          showNotification: true,
          message: error.response.data.message || error.message,
          type: error.response.data.message ? 'info' : 'error'
        }
      })
      setLoading(false)
    }
  }

  return (
    <>
      <Breadcrumb page='Activity' subPage='Click the button' bg='-2' />
      <section className="mt-minus-300">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="affiliate-single-wrapper pt-120 pb-120">
                <div style={{ width: '70%'}} className="affiliate-single-wrapper__obj">
                  <img src="/assets/images/elements/affiliate-obj.png" alt="img" />
                </div>
                <div className="section-header mb-0">
                  <span className="section-sub-title">Reduce Your wait time</span>
                  <h2 className="section-title font-weight-bold">{clicks} clicks</h2>
                  <p>You now have {cool_down_period} left.</p>
                  <p>click on the button, watch the new tab load close it and do it again.</p>
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={buttonClick}
                    onTouchStart={buttonClick}
                    // href='/'
                    className="cmn-btn text-capitalize mt-4"
                  >
                    { loading ? 'loading...': 'click me' }
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ClickTheButton;
