import React, { useState, useEffect } from 'react';
import './style.css';

function CountdownTimer({
  targetDate,
  onCountdownEnd,
  showMilliseconds = false,
  showDays = true,
  showHours = true,
  pin
}) {
  const [countDown, setCountDown] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountDown(calculateTimeLeft(targetDate));
    }, 1000); 

    return () => {
      clearInterval(intervalId);
    };
  }, [targetDate]);

  if (countDown <= 0) {
    onCountdownEnd();
  }

  const { days, hours, minutes, seconds } = formatTime(countDown);

  const timeSegments = [];
  if (showDays) {
    timeSegments.push(
      <div key="days" className="time-segment">
        <span className="time-value">{days}</span>
        <span className="time-label">Days</span>
      </div>
    );
  }
  if (showHours) {
    timeSegments.push(
      <div key="hours" className="time-segment">
        <span className="time-value">{hours}</span>
        <span className="time-label">Hours</span>
      </div>
    );
  }
  timeSegments.push(
    <div key="minutes" className="time-segment">
      <span className="time-value">{minutes}</span>
      <span className="time-label">Minutes</span>
    </div>
  );
  timeSegments.push(
    <div key="seconds" className="time-segment">
      <span className="time-value">{seconds}</span>
      <span className="time-label">Seconds</span>
    </div>
  );

  return (
    <div>
      {(countDown <= 0 && pin !== '') && <div>Are you quick enough!?</div>}
      <div className="countdown-timer">
        {timeSegments}
      </div>
    </div>
  );
}

function calculateTimeLeft(targetDate) {
  const targetTime = new Date(targetDate).getTime();
  const now = new Date().getTime();
  const difference = targetTime - now;

  // Check if countdown is over
  if (difference <= 0) {
    return 0;
  }

  return difference;
}

function formatTime(time) {
  const days = Math.floor(time / (1000 * 60 * 60 * 24));
  const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((time % (1000 * 60)) / 1000);
  const milliseconds = (time % 1000) / 1000; // Convert milliseconds to decimal fraction

  return { days, hours, minutes, seconds, milliseconds };
}

export default CountdownTimer;
