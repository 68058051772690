import Types from './type';
import initialState from './state';

const appReducer = (state = initialState, action) => {
  // let newState = {};

  switch (action.type) {
    case Types.FETCH_FASTEST_FINGER_PIN:
      return {
        ...state,
        fastestFingers: {
          ...state.fastestFingers,
          loading: true,
          timerStarted: false,
        }
      }

    case Types.FETCH_FASTEST_FINGER_PIN_SUCCESS:
      console.log(action.payload)

      if (action.payload.status) {
        return {
          ...state,
          fastestFingers: {
            ...state.fastestFingers,
            endTime: action.payload.data?.endTime || state.fastestFingers.endTime,
            startTime: action.payload.data?.startTime || state.fastestFingers.startTime,
            pin: action.payload.data?.pin || state.fastestFingers.pin,
            loading: false,
            timerStarted: true,
          }
        }
      }
      return state;

    case Types.FETCH_FASTEST_FINGER_PIN_FAIL:
      let newState = {
        ...state,
        fastestFingers: {
          ...state.fastestFingers,
          error: action.payload,
          loading: false,
          timerStarted: false,
        }
      }
      return newState;

    case Types.ERROR:
      return {
        ...state,
        showNotification: true,
        message: action.payload.error,
        type: 'error'
      }
    
    case Types.TOGGLE_NOTIFICATION:
      return {
        ...state,
        showNotification: action.payload.showNotification,
        message: action.payload.message,
        type: action.payload.type || 'info'
      }
    default:
      return state;
  }
}

export default appReducer;
