const quiz = {
  category_id: '',
  loading: false,
  error: '',
  network: '',
  phone_number: '',
  session_id: localStorage.getItem('session_id') || '',
  categories: [],
  question: JSON.parse(localStorage.getItem('question') || '{}') || {
      id: 0,
      question_text: "",
      question_type: "",
      option_a: "",
      option_b: "",
      option_c: "",
      option_d: "",
      category: "",
      difficulty: "3",
      correct_option: "d"
  },
  recharge_pin: JSON.parse(localStorage.getItem('recharge_pin')) || {
    id: '',
    pin_code: '',
    network: ''
  },
  win_cool_down_period: 3 * 60 * 60,
  correct_answer: true,
  fails_left: 0,
  score: localStorage.getItem('score') || 0,
  passed: localStorage.getItem('passed') || 0,
  failed: localStorage.getItem('failed') || 0,
  clicks: localStorage.getItem('clicks') || 0,
  cool_down_period: localStorage.getItem('cool_down_period') || 0,
  session_status: localStorage.getItem('session_status') || 'ended',
  required_correct_answers: 0,
  endTime: localStorage.getItem('endTime')  || Date.now(),
  startQuiz: localStorage.getItem('startQuiz') || false
}

export default quiz;
