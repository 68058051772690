import user from './reducers/user/state';
import quiz from './reducers/quiz/state';
import app from './reducers/app/state';

const initialState = {
    user,
    quiz,
    app,
};

export default initialState;