import React, { createContext, useContext } from "react";
import { StateContext } from "../../Providers/stateProvider";


const AuthContext = createContext(); export const AuthProvider = ({ children }) => {
  const { user: { token } } = useContext(StateContext)
  
  const isAuthenticated = Boolean(token); 
  
  return (
    <AuthContext.Provider value={{ isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
}; 

export const useAuth = () => {
  const context = useContext(AuthContext);
  
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  } return context;
};
