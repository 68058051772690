import Types from './type';
import initialState from './state';

/** 
 * won - onclose modal, clear quiz session in memory
 * failed - clear session
 * ongoing - do nothing
 * ended - clear
 */
const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_CATEGORIES:
      return {
        ...state,
        categories: action.payload.data,
      };

    case Types.START_QUIZ_SESSION:
      return {
        ...state,
        loading: true
      };

    case Types.START_QUIZ_SESSION_SUCCESS:
      const startQuizData = action.payload.data;
      localStorage.setItem('startQuiz', true);
      localStorage.setItem('question', JSON.stringify(startQuizData?.question));
      localStorage.setItem('endTime', startQuizData?.endTime);
      localStorage.setItem('session_id', startQuizData?.session_id);
      localStorage.setItem('question_id', startQuizData?.question?.id);
      return {
        ...state,
        session_id: startQuizData.session_id,
        question: startQuizData.question,
        endTime: startQuizData.endTime,
        startQuiz: true,
        loading: false,
        session_status: startQuizData.session_status
      };

    case Types.START_QUIZ_SESSION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case Types.SUBMIT_QUESTION:
      return state;

    case Types.SUBMIT_QUESTION_SUCCESS:
      const submitQuestionData = action.payload.data;
      // do history in localStorage
      if (submitQuestionData.session_status === 'won') {
        localStorage.setItem('recharge_pin', JSON.stringify(submitQuestionData.recharge_pin))
        localStorage.setItem('score', submitQuestionData.score)
        localStorage.setItem('passed', submitQuestionData.passed)
        localStorage.setItem('failed', submitQuestionData.failed)
        localStorage.setItem('session_status', submitQuestionData.session_status)
        localStorage.setItem('cool_down_period', submitQuestionData.cool_down_period)
        
      }  else if (submitQuestionData.session_status === 'failed') {
        localStorage.setItem('score', submitQuestionData.score)
        localStorage.setItem('passed', submitQuestionData.passed)
        localStorage.setItem('failed', submitQuestionData.failed)
        localStorage.setItem('cool_down_period', submitQuestionData.cool_down_period)
      } else {
        localStorage.setItem('startQuiz', true);
        localStorage.setItem('question', JSON.stringify(submitQuestionData?.question));
        localStorage.setItem('endTime', submitQuestionData?.endTime);
        localStorage.setItem('session_id', submitQuestionData?.session_id);
        localStorage.setItem('question_id', submitQuestionData?.question?.id || {});
      }
      return {
        ...state,
        ...submitQuestionData,
      };

    case Types.SUBMIT_QUESTION_FAIL:
      return {
        ...state
      };

    case Types.QUIZ_OVER:
      localStorage.removeItem('startQuiz');
      localStorage.removeItem('question');
      localStorage.removeItem('endTime');
      localStorage.removeItem('session_id');
      localStorage.removeItem('question_id');
      return {
        ...state,
        startQuiz: false,
        session_status: 'ended'
      };

    // case Types.CLICK_BUTTON:
    //   return {
    //     ...state,
    //     loading: true,
    //   }
    case Types.CLICK_BUTTON_SUCCESS:
      localStorage.setItem('clicks', action.payload.data.clicks);
      localStorage.setItem('cool_down_period', action.payload.data.timeout);
    return {
      ...state,
      // loading: false,
      clicks: action.payload.data.clicks,
      cool_down_period: action.payload.data.timeout,
    }

    case Types.CLICK_BUTTON_FAIL:
      localStorage.setItem('clicks', 0);
      localStorage.setItem('cool_down_period', 0);
    return {
      ...state,
      clicks: 0,
      cool_down_period: 0,
      // loading: false,
    }

    default:
      return state;
  }
};

export default quizReducer;
