/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import { StateContext } from '../../Providers/stateProvider';
import quizTypes from '../../Providers/reducers/quiz/type';
import getUserFingerprint from '../../utils/userFingerprint';
import Modal from '../Modal';
import QuizOver from './QuizOver';
import './style.css';

const Questions = ({ question, endTime }) => {
  const { quiz: { session_id, session_status, ...quizData}, dispatch } = useContext(StateContext);
  const [show, setShow] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  useEffect(() => {
    const $ = window.jQuery;
    $('.clock').attr('data-clock', endTime);
    const getDate = $('.clock').attr('data-clock');

    $('.clock').countdown(getDate, function (event) {
      $(this).html(event.strftime(''
        + '<div><span>%D</span><p>days</p></div>'
        + '<div><span>%H</span><p>hours</p></div>'
        + '<div><span>%M</span><p>minutes</p></div>'
        + '<div><span>%S</span><p>seconds</p></div>'));
    });

    return () => {
      $('.clock').countdown(new Date().getTime());
    };
  }, [endTime]);

  useEffect(() => {
    if (session_status === 'won') {
      setShow(true)
    } else if (session_status === 'failed') {

    } else if (session_status === 'ended') {

    }
  }, [session_status])

  const submitAnswer = async (answer) => {
    setSelectedAnswer(answer);

    try {
      const now = new Date().getTime();
      const delay = endTime - now;
      if (delay < 1) {
        return Swal.fire({
          title: "Session over!",
          text: "Please start a new one!",
          icon: "info",
          didClose: () => {
            dispatch({
              type: quizTypes.QUIZ_OVER
            });
          }
        });
      }

      dispatch({
        type: quizTypes.SUBMIT_QUESTION,
      });

      const fingerprint = await getUserFingerprint();
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/quiz/fetchQuestion`, {
        session_id,
        answer,
        question_id: question.id,
      }, {
        headers: {
          fingerprint: fingerprint.fingerprintHash,
          fingerprintData: JSON.stringify(fingerprint.fingerprintData)
        }
      });

      if (response.data.status) {
        setCorrectAnswer(response.data.data.correct_answer);
        setShowFeedback(true);

        setTimeout(() => {
          setShowFeedback(false);
          setSelectedAnswer(null);
          dispatch({
            type: quizTypes.SUBMIT_QUESTION_SUCCESS,
            payload: response.data
          });
        }, 500);
      } else {
        // dispatch({
        //   type: 'TOGGLE_NOTIFICATION',
        //   payload: { message: response.data.message, showNotification: true }
        // });

        Swal.fire({
          title: "Session over!",
          text: response.data.message,
          icon: "warning",
          didClose: () => {
            dispatch({
              type: quizTypes.QUIZ_OVER
            });
          }
        });
      }
    } catch (error) {
      dispatch({
        type: 'ERROR',
        payload: { error: error.message }
      });
    }
  };

  const getOptionClass = (option) => {
    if (!showFeedback || selectedAnswer !== option) return '';
    return correctAnswer ? 'correct' : 'wrong';
  };

  const closeModal = () => {
    setShow(false)
    dispatch({
      type: quizTypes.QUIZ_OVER
    });
    // dispatch session ended
  }

  return (
    <section className="pb-120 mt-minus-300">
      <Modal title="Quiz Over." size='md' show={show} onClose={closeModal}>
        <QuizOver quizData={quizData} />
      </Modal>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="clock-wrapper">
              <p className="mb-2">Quiz session ends in:</p>
              <div className="clock" data-clock={"2000/07/10"}></div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="contest-cart">
              <div className="contest-cart__right" style={{ width: '100%' }}>
                {(
                  <>
                    <h4 className="subtitle">{question.category}</h4>
                    <h3 className="">{question.question_text}</h3>
                    <div className="content-block">
                      <div id='options' className="row mb-none-30 ">
                        <div className="col-lg-6 col-sm-6 mb-30">
                          <div className={`icon-item ${getOptionClass('a')}`} onClick={() => submitAnswer('a')}>
                            <div className="icon-item__content">
                              {/* <p>(a)</p> */}
                              <span>{question.option_a}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 mb-30">
                          <div className={`icon-item ${getOptionClass('b')}`} onClick={() => submitAnswer('b')}>
                            <div className="icon-item__content">
                              <span>{question.option_b}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 mb-30">
                          <div className={`icon-item ${getOptionClass('c')}`} onClick={() => submitAnswer('c')}>
                            <div className="icon-item__content">
                              <span>{question.option_c}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 mb-30">
                          <div className={`icon-item ${getOptionClass('d')}`}>
                            <div className="icon-item__content" onClick={() => submitAnswer('d')}>
                              <span>{question.option_d}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <ul className="social-links align-items-center">
                      <li>Share :</li>
                      <li><a href="#0"><i className="fab fa-facebook-f"></i></a></li>
                      <li><a href="#0"><i className="fab fa-twitter"></i></a></li>
                      <li><a href="#0"><i className="fab fa-linkedin-in"></i></a></li>
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Questions;
