const types = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  REGISTER: 'REGISTER',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_ERROR: 'REGISTER_ERROR',

  GET_ACTIVITIES: 'GET_ACTIVITIES',
  GET_ACTIVITIES_SUCCESS: 'GET_ACTIVITIES_SUCCESS',
  GET_ACTIVITIES_ERROR: 'GET_ACTIVITIES_ERROR',

  GET_QUIZ_SESSION: 'GET_QUIZ_SESSION',
  GET_QUIZ_SESSION_SUCCESS: 'GET_QUIZ_SESSION_SUCCESS',
  GET_QUIZ_SESSION_ERROR: 'GET_QUIZ_SESSION_ERROR',

  GET_REFERRALS: 'GET_REFERRALS',
  GET_REFERRALS_SUCCESS: 'GET_REFERRALS_SUCCESS',
  GET_REFERRALS_ERROR: 'GET_REFERRALS_ERROR',

  LOGOUT: 'LOGOUT',
  ERROR: 'ERROR'
}

export default types;