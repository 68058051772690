import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import mainReducer from './mainReducer';
import initialState from './initialState';

export const StateContext = React.createContext();

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const values = { ...state, dispatch };
  return (
    <StateContext.Provider value={values}>
      {children}
    </StateContext.Provider>
  );
};

StateProvider.propTypes = {
  children: PropTypes.instanceOf(Object)
};

export default StateProvider;
