import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumb = ({ page, subPage, bg='' }) => {
  // const style = page.toLowerCase() === 'quiz' ? {
  //   paddingTop: '65px',
  //   paddingBottom: '300px'
  // } : {}
  return (
    <div className="inner-hero-section">
      <div className="bg-shape">
        <img src={`/assets/images/elements/inner-hero-shape${bg}.png`} alt="img" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <ul className="page-list">
              <li><Link to="/">Home</Link></li>
              <li className={ subPage ? '' : 'active'}>
                { subPage ? <Link to={`/${page.toLowerCase()}`}>{page}</Link> : page}
              </li>
              { subPage && <li className="active">{subPage}</li> }
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Breadcrumb
