import React, { useEffect } from 'react'
import getUserFingerprint from '../../utils/userFingerprint'
import axios from 'axios';
import types from '../../Providers/reducers/user/type';

const UpComingActivities = ({ activities, dispatch }) => {
  const fetchActivities = async () => {
    try {
      const fingerprint = await getUserFingerprint();
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/activity`, {
        headers: {
          fingerprint: fingerprint.fingerprintHash,
          location: JSON.stringify(fingerprint.location),
          fingerprintData: JSON.stringify(fingerprint.fingerprintData)
        }
      })

      if (response.status) {
        dispatch({
          type: types.GET_ACTIVITIES_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: types.REGISTER_ERROR,
          payload: { error: response.message }
        });
      }
    } catch (error) {
      dispatch({
        type: 'ERROR',
        payload: { error: error.response.data.message || error.message }
      });
    }
  }

  useEffect(() => {
    fetchActivities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="upcoming-draw-wrapper">
      <h3 className="title">Activity list</h3>
      <div className="draw-ticket-slider" style={{ display: 'flex' }}>
        {
          activities.length > 0 ? activities.map((data, index) => (
            <div
              key={index}
              className="draw-single-ticket"
              style={{ cursor: 'pointer' }}
            >
              <div className="draw-single-ticket__header">
                <div className="left">{data.name}</div>
                <div className="right">{data.description}</div>
              </div>
              <div className="circle-divider"><img src="/assets/images/elements/circle-border.png" alt="ige" /></div>
              {/* <button>Start</button> */}
            </div>
          )) : <p>No activities yet.</p>
        }
      </div>
    </div>
  )
}

export default UpComingActivities
