import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import copy from 'copy-to-clipboard'
import getUserFingerprint from '../../utils/userFingerprint';
import { StateContext } from '../../Providers/stateProvider';
import types from '../../Providers/reducers/user/type';
import moment from 'moment';

const Referral = () => {
  const { user: { data, activities, quizSession, referrals, token }, dispatch } = useContext(StateContext)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [copyText, setCopyText] = useState('Copy Link');
  const ref_link = `http://lootloot.com.ng/register?ref=${data?.data.referral_code}`;

  const fetchQuizSession = async () => {
    try {
      const fingerprint = await getUserFingerprint();
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/referrals?page=${page}&limit=${limit}`, {
        headers: {
          authorization: `Bearer ${token}`,
          fingerprint: fingerprint.fingerprintHash,
          location: JSON.stringify(fingerprint.location),
          fingerprintData: JSON.stringify(fingerprint.fingerprintData)
        }
      })

      if (response.status) {
        dispatch({
          type: types.GET_REFERRALS_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: types.GET_REFERRALS_ERROR,
          payload: { error: response.message }
        });
      }
    } catch (error) {
      if (error.response.status !== 401) {
        dispatch({
          type: 'ERROR',
          payload: { error: error.response.data.message || error.message }
        });
      }
    }
  }

  const loadMore = async () => {
    
  }

  useEffect(() => {
    fetchQuizSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  const copyLink = (e) => {
    e.preventDefault();
    copy(ref_link)
    setCopyText('Copied to clipboard!')
    setTimeout(() => setCopyText('Copy link'), 2000)
  }

  return (
    <div className="col-lg-8 mt-lg-0 mt-4">
      <div className="referral-link-wrapper">
        <h3 className="title">Partners</h3>
        <div className="copy-link">
          <span className="copy-link-icon"><i className="las la-link"></i></span>
          <span className="label">Referral Link :</span>
          <div className="copy-link-inner">
            <form data-copy='true' onSubmit={copyLink}>
              <input type="text" value={ref_link} data-click-select-all />
              <input type="submit" value={copyText} />
            </form>
          </div>
        </div>
      </div>

      <div className="referral-overview mt-30">
        <div className="row justify-content-center mb-none-30">
          <div className="col-lg-5 col-sm-6 mb-30">
            <div className="referral-crad">
              <div className="referral-crad__icon"><img src="/assets/images/icon/referral/1.png" alt="image" /></div>
              <div className="referral-crad__content">
                <h3 className="number">{referrals?.meta.total || 0}</h3>
                <span>Total Referral</span>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-sm-6 mb-30">
            <div className="referral-crad">
              <div className="referral-crad__icon"><img src="/assets/images/icon/referral/2.png" alt="image" /></div>
              <div className="referral-crad__content">
                <h3 className="number">{data.data.coins}</h3>
                <span>Cumulative Cowries</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="referral-transaction">
        <div className="all-transaction__header">
          <h3 className="title">Your Referrals:</h3>
          <div className="date-range">
            {/* <input type="text" data-range="true" data-multiple-dates-separator=" - " data-language="en" className="datepicker-here form-control" data-position='top left' placeholder="min - max date" /> */}
            {/* <i className="las la-calendar-alt"></i> */}
          </div>
        </div>
        <div className="table-responsive-lg">
          <table>
            <thead>
              <tr>
                {/* <th>s/n</th> */}
                <th>Date</th>
                <th>Full Name</th>
                <th>phone number</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {
                referrals.data.map((data, index) => (
                  <tr key={index}>
                    {/* <td>{index + 1}</td> */}
                    <td>
                      <div className="date">
                        {moment(data.created_on).format('DD.MMM.YYYY')}
                      </div>
                    </td>
                    <td>{data.full_name}</td>
                    <td>{data.phone_number}</td>
                    <td>ACTIVE</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <div className="load-more">
          <button type="button">Show More <i className="las la-angle-down ml-2"></i></button>
        </div>
      </div>
    </div>
  )
}

export default Referral
