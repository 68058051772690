import React from 'react'

const UserInfo = ({ user }) => {

  const { full_name, coins, network, phone_number } = user;
  return (
    <div className="user-card">
      <div className="avatar-upload">
        <div className="obj-el">
          <img src="/assets/images/elements/team-obj.png" alt="tean" /></div>
        {/* <div className="avatar-edit">
          <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" />
          <label for="imageUpload"></label>
        </div> */}
        <div className="avatar-preview">
          <div id="imagePreview" style={{ backgroundImage: 'url(/assets/images/ll_logo2.png)' }}>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'left' }}>
        <h3 className="user-card__name">{full_name}</h3>
        <span className="user-card__id">{coins}ç</span><br />
        <span className="user-card__id">{network?.toUpperCase()}</span> <br />
        <span className="user-card__id">{phone_number}</span>
      </div>
    </div>
  )
}

export default UserInfo
