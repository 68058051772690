import React, { useContext, useEffect } from 'react'
import axios from 'axios';
import Hero from '../../components/Hero';
import Contest from '../../components/Contest';
import Jackpot from '../../components/Jackpot';
import { StateContext } from '../../Providers/stateProvider'
import appTypes from '../../Providers/reducers/app/type';
import getUserFingerprint from '../../utils/userFingerprint';
import OnClick from '../../components/Helmet/OnClick';

const HomePage = () => {
  const { app: { fastestFingers: { timerStarted, pin } }, dispatch } = useContext(StateContext)

  const getFastestFingerPin = async () => {
    const fingerprint = await getUserFingerprint()
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/app/fastest-fingers`, {
          headers: {
            fingerprint: fingerprint.fingerprintHash,
            location: JSON.stringify(fingerprint.location),
            fingerprintData: JSON.stringify(fingerprint.fingerprintData)
          }
        });

        dispatch({
          type: appTypes.FETCH_FASTEST_FINGER_PIN_SUCCESS,
          payload: response.data
        })
      } catch (error) {
        dispatch({
          type: appTypes.FETCH_FASTEST_FINGER_PIN_FAIL,
          payload: error.message
        })
      }
    }

  const handleCountdownEnd = async () => {
    if (timerStarted && pin === '') {
      await getFastestFingerPin();
    }
  }

  useEffect(() => {
    getFastestFingerPin()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // console.log('I render...')

  return (
    <>
      <OnClick />
      <Hero />
      <Contest handleCountdownEnd={handleCountdownEnd} />
      <Jackpot />
    </>
  )
}

export default HomePage
